
import './App.css';
import './tailwind.output.css';

import { Document, Page,pdfjs } from 'react-pdf'; 
import React from 'react'; 
import queryString from 'query-string';
import './custom.css';

import InvoiceTable from './InvoiceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';





class Fpdf extends React.Component {

    
    constructor(props) {
        super(props);
        this.state = {
            externalData: null,
            isLoading:true,
            numPages:1,
            showInvoices: false,
            
            pageNumber:1,
            
        };
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 
    
 
      }


      onDocumentLoadSuccess({ numPages }) { 
       


      } 
      
      changePage(offset) { 
       // this.state.setPageNumber(prevPageNumber => prevPageNumber + offset); 
      } 
      
      previousPage() { 
        this.changePage(-1); 
      } 
      
      nextPage() { 
        this.changePage(1); 
      } 




      componentDidMount(){
       
          this.isSetting=true;
          let params = queryString.parse(window.location.href);

          if(params.ha == null){
            this.setState({ isLoading:false });
            return;
          }

          if (params.showInvoices !=null){
            this.setState({showInvoices:true});
          }

          var url=null;
          if (params.fid!=null){
             url='https://login14.filofact.be/api/client/getInvoiceInfo?fid='+params.fid+'&cid='+params.cid+'&ha='+params.ha+'&usrid='+params.usrid;
            if (params.dev !=null){
              url='http://localhost:9092/api/client/getInvoiceInfo?fid='+params.fid+'&cid='+params.cid+'&ha='+params.ha+'&usrid='+params.usrid;
             
            }
          }else{
            url='https://login14.filofact.be/api/client/getQuotationInfo?qid='+params.qid+'&cid='+params.cid+'&ha='+params.ha+'&usrid='+params.usrid;
            if (params.dev !=null){
              url='http://localhost:9092/api/client/getQuotationInfo?qid='+params.qid+'&cid='+params.cid+'&ha='+params.ha+'&usrid='+params.usrid;
             
            }
          }


 

          
  
          fetch(url)
          .then(response => response.json())
          .then((jsonData) => {
            
            // jsonData is parsed json object received from url
         
            this.setState({ externalData: jsonData,isLoading:false });
          })
          .catch((error) => {
            // handle your errors here
            console.error(error)
          })
        

      }

      handleRowClick = (url) => {
        window.location.href = url+'&showInvoices=true'; 
      //  window.open(url, '_blank');
      }

      toggleInvoices = () => {
        this.setState(prevState => ({
          showInvoices: !prevState.showInvoices // Schakel de zichtbaarheid van de facturenlijst
        }));
      }


      render() {

          var scale=0.5

          if (window.innerWidth > 500){
            scale=0.9;
          }

          if (window.innerWidth > 780){
            scale=0.9;
          }

          if (window.innerWidth > 1080){
            scale=1.1;
          }



          if (window.innerWidth > 1280){
            scale=1.3;
          }
      
        
        

    
          if (this.state.isLoading ===true){
           
            return (
              
                <h1>Loading</h1>
              );
          }else{


            if (this.state.externalData === null){
              return (
                <div className="bg-auto bg-no-repeat bg-center h-screen" id="bg">
                        


                </div>

          
              );
            }else{

          
            return (



              <div className="main bg-gray-100"> 
              <div className="lg:flex  min-h-screen">
              <div className="flex-none w-full  lg:max-w-md    h-full  " >
              <div className="p-8 w-full  lg:max-w-md lg:fixed lg:h-full relative overflow-y-auto">
              <div className="flex-grow overflow-y-auto">
                <div>
              <button className="inline-block align-middle uppercase px-8 py-2 bg-blue-600 text-blue-50 max-w-max shadow-sm hover:shadow-md text-center" onClick={()=> window.open(this.state.externalData.invoice.url, "_blank")}> Download</button>
              </div>
 
              {this.state.externalData.invoice.paid === false?
              <div className="py-8 text-left flex-grow">

            
              <div className="p-1"><div className="unpaid-invoice-alert">
  <FontAwesomeIcon icon={faExclamationTriangle} className="alert-icon" />
  <span>Deze factuur is nog niet betaald.</span>
</div>
<br/>
      
              <table className="table-auto">
              <tbody>
              <tr>
                   <td className="pr-6"> 
                   Begunstigde
                   </td>
                   <td>
                   {this.state.externalData.paymentinfo.name}
                   </td>
                   </tr>
              <tr>
                  <td>
                      Bedrag
                  </td>
                  <td>
                    €  {this.state.externalData.paymentinfo.amount}
                  </td>
              </tr>
                 <tr>
                   <td>
                   IBAN
                   </td>
                   <td>
                   {this.state.externalData.paymentinfo.iban}
                   </td>
                   </tr>
                   <tr>
                   <td>
                   BIC
                   </td>
                   <td>
                   {this.state.externalData.paymentinfo.bic}
                   </td>
                   </tr>
                   <tr>
                   <td>
                   Mededeling
                   </td>
                   <td>
                   {this.state.externalData.invoice.structured}
                   </td>
                   </tr>
                 
                 </tbody>

              </table>
              

            
              <br/>
              Om de bank details automatisch te laten invullen kunt u gebruik maken van de QR code. 
              U kunt deze scannen met de mobiele bank app als deze ondersteund wordt door uw bank. 
              <img  className="mx-auto md:pt-4 lg:pt-8 w-44" alt="QE" src={this.state.externalData.paymentinfo.QR} />
           
         {/*
              <ul>
        <li> 1. Open je bankapplicatie op je smartphone of tablet.</li>
        <li> 2. Selecteer de optie "Scan QR-code" of iets vergelijkbaars, afhankelijk van je bankapp.</li>
        <li> 3. Richt de camera van je apparaat op de onderstaande QR-code.</li>
        <li> 4. Volg de instructies op je scherm om de betaling veilig af te ronden.</li>
    </ul>*/}
              </div>
           
          
         
              <br/>Op dit moment ondersteunen de mobiele apps van banken zoals Argenta, ASN, Belfius, Bunq, BNP Paribas Fortis, ING, KBC, Knab, SNS en VDK betalingen via gescande QR-codes. 
              </div>
              :
              <div className="py-8 text-center">

            
              <div className="paid-invoice-alert">
  <FontAwesomeIcon icon={faCheckCircle} className="alert-icon" />
  <span>Deze factuur is betaald.</span>
</div>
              
              </div>
            
              }
              <button className="invoice-toggle-button" onClick={this.toggleInvoices}> {this.state.showInvoices ? "Verberg andere facturen" : "Toon andere facturen"}</button><br/><br/>
              {this.state.showInvoices && this.state.externalData && (
               
    <InvoiceTable
      invoices={this.state.externalData.invoices}
      selectedInvoiceId={this.state.externalData.invoice.id}
      onRowClick={this.handleRowClick}
      openInvoiceDetails={this.openInvoiceDetails}
    />
        )}
              </div>
        
              </div>

            
              </div>

                <div className="flex-1 bg-gray-800 pd-auto ml-auto p-12">
              <Document 
                file={this.state.externalData.invoice.url}
               // file='http://127.0.0.1:9092/api/client/getInvoice?usrid=1&fid=202300002&cid=1937&ha=b2a06acedd6617ccdba80350baacd13a'
            
                onLoadSuccess={this.onDocumentLoadSuccess} 
              > 
                <Page pageNumber={this.state.pageNumber}     scale={scale}
                
                style="display:flex" /> 
              </Document> 

              </div> 
   
              </div>
              </div>
              );
          }
        }
      }
}



function App() {
    return (
        <div className="App"> 
         <Fpdf /> 
      
   </div> 
    );

     
}

export default App;